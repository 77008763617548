import { request } from './XMLHttpRequest';
import { session } from './app';

export var server: string;
export var baseurl: string;
export var endpoint: string;

declare namespace module {
    let hot: any;
}

endpoint = `https://milrecargas.com/endpoint.php`;
server = "milrecargas.com";
baseurl = "https://milrecargas.com";

if (window.location.host == "localhost:1234") {
    endpoint = "http://localhost:8080/endpoint.php";
    server = "localhost:8080";
    baseurl = "http://localhost:8080"
}

const timezone = new Date().getTimezoneOffset();

export var forcedLogout = false;

export type AccountType = 'standard' | 'enhanced' | 'VIP';
export type currency = {
    code: string,
    name: string,
    plural: string,
    symbol: string
};
export type country = {
    code: string,
    name: string,
    flag_url: string,
    currencies: [currency],
    hidden?: boolean
};
export type Notification = {
    id: number,
    title?: string,
    text?: string,
    subtext?: string,
    points?: number,
    image_url?: string,
    action_url?: string,
    show_popup: boolean,
};
export type loginResponse = {
    success: boolean,
    logout?: boolean,
    message?: string,
    email: string,
    name?: string,
    surname?: string,
    currency: currency,
    country: country,
    wallet: number,
    points: number,
    pointsConversionRate?: number,
    pointsConversionResult?: number,
    mobile?: string,
    documentType?: string,
    document?: string,
    notifications?: Notification[],
    account_type?: AccountType,
    session?: string,
};
export type errorResponse = {
    success: true,
    message: string,
    logout?: boolean
};
export type Product  = {
    id: number,
    name: string,
    image: string,
    imageURL: string,
    cards: number[],
    currency: currency,
    color: string,
    titleColor?: string,
    tab: string,
    plus: number,
    conversion: number,
    promotion: number,
    paysafecard: number,
    extraInfo?: string,
    extraInfoTitle?: string,
};
export type PaymentMethod = {
    id: string,
    name: string,
    imageURL: string,
    cost: string,
    delay: string,
    available: string,
    staticURL?: string,
};
export type Purchase = {
    currency: string,
    amount: string,
    status: string,
    product: number,
    creation_date: string,
    fulfillment_date: string
};
export type Recharge = {
    state: string,
    creation_date: string,
    fulfillment_date?: string,
    amount: string,
    payment_method?: string,
    instructions?: string,
    account?: string,
    code?: string
};
export type Withdraw = {
    state: string,
    creation_date: string,
    fulfillment_date?: string,
    amount: string,
    withdraw_method?: string,
    instructions?: string,
    account?: string,
    code?: string
};
export type CheckRechargesResponse = {
    success: boolean,
    logout?: boolean,
    message?: string,
    paymentMethods: PaymentMethod[],
    pending?: Recharge[],
    recharges: number[],
    wallet: number,
    points: number,
    notifications?: Notification[],
};
export type CheckWithdrawsResponse = {
    success: boolean,
    logout?: boolean,
    message?: string,
    withdrawMethods: PaymentMethod[],
    withdraws: number[],
    pending?: Withdraw,
};
export type PointsHistoryEntry = {
    date: string,
    reason: string,
    points: number
};
export type CheckPointsResponse = {
    success: boolean,
    logout?: boolean,
    message?: string,
    points: number,
    rate: number,
    conversion: number,
    history: PointsHistoryEntry[]
};
export type ValidatePhoneResponse = {
    success: boolean,
    message?: string,
    code: number
};
export type ConfirmPhoneResponse = {
    success: boolean,
    logout?: boolean,
    message?: string,
    mobile: number
};
export type ConvertPointsResponse = {
    success: boolean,
    logout?: boolean,
    message?: string,
    converted: number,
    amount: number,
    points: number,
    wallet: number,
    history: PointsHistoryEntry[]
};
export type PurchaseResponse = {
    success: boolean,
    message?: string,
    points: number,
    wallet: number,
}
export type CheckNotificationsResponse = {
    success: boolean,
    logout?: boolean,
    message?: string,
    notifications: Notification[]
}
export type HistoryResponse = {
    success: boolean,
    logout?: boolean,
    message?: string,
    recharges: Recharge[],
    purchases: Purchase[],
    withdraws: Withdraw[],
    products: Product[],
    payment_methods: PaymentMethod[],
}
export type FAQEntry = {
    id: number,
    subject: string,
    content: string
}
export type FAQResponse = {
    success: boolean,
    message?: string,
    logout?: boolean,
    faq: FAQEntry[],
}
export type DocumentType = {
    id: string,
    name: string,
}
export type DocumentTypesResponse = {
    success: boolean,
    message?: string,
    documentTypes: DocumentType[],
}
export type MercadoPagoPreferenceResponse = {
    success: boolean,
    message?: string,
    id?: string,
    new?: boolean,
    paymentMethods: PaymentMethod[]
}
export type PurchaseMethodsResponse = {
    success: boolean,
    message?: string,
    paymentMethods?: PaymentMethod[]
}
export type NibuizTokenResponse = {
    success: boolean,
    message?: string,
    token?: { sessionKey: string, expirationTime: number },
    merchantId?: string,
    staticURL?: string,
    purchase?: number,
    amount?: number,
}
export type KushkiCartIDResponse = {
    success: boolean,
    message?: string,
    cartID?: number,
}
export type BillingAddressResponse = {
    success: boolean,
    message?: string,
    address?: string,
    region?: string,
    city?: string,
    zip?: string,
    country?: string
}

var sessionId: string;

export function setSessionID(value: string) {
    sessionId = value;
}
export function getSessionID() {
    return sessionId;
}

export async function login (email: string, password: string, code: string) {
    var response = await(command("login", { email, password, code })) as loginResponse;
    if (response.session) {
        sessionId = response.session;
        forcedLogout = false;
    }
    return response;
}
export async function sendReminder(email: string) {
    return await (command("send-reminder", { email }));
}
export async function changePassword(email: string, password: string, code: string) {
    return await (command("change-password", { email, password, code }));
}
export async function sendContactMessage(subject: string, content: string) {
    return await (command("contact-message", { subject, content }));
}
export async function logout() {
    sessionId = undefined;
    return await(command("logout"));
}
export async function countries() {
    let result = await command("countries");
    return result.countries as [country];
}
export async function products(country: string, currency: string) {
    let result = await command("products", { country, currency }) as { success: boolean, message?: string, products: Product[] }
    if (result.success == false) { throw result; }
    return result.products;
}
export async function markNotificationAsShown(id: number) {
    let result = await command("notification-shown", { id: id.toString() }) as loginResponse;
    if (result.success == false) {
        throw result;
    }
    session.user = result;
    return result;
}
export async function getFAQ() {
    return await command("get-faq") as FAQResponse;
}
export async function checkRecharges() {
    return await command("check-recharges") as CheckRechargesResponse;
}
export async function checkWithdraws() {
    return await command("check-withdraws") as CheckWithdrawsResponse;
}
export async function checkPoints() {
    return await command("check-points") as CheckPointsResponse;
}
export async function validatePhone(phone: string) {
    return await command("validate-phone", { phone }) as ValidatePhoneResponse;
}
export async function confirmPhone(code: string) {
    return await command("confirm-phone", { code }) as ConfirmPhoneResponse;
}
export async function convertPoints() {
    return await command("convert-points") as ConvertPointsResponse;
}
export async function purchase(product: number, amount: number, phone?: string) {
    return await command("purchase", { product: product.toString(), amount: amount.toString(), phone }) as PurchaseResponse;
}
export async function recharge(amount: number, method: string, details?: string) {
    return await command("recharge", { method, amount: amount.toString(), details }) as CheckRechargesResponse;
}
export async function withdraw(amount: number, method: string, name: string, bank: string, account: string, details: string) {
    return await command("withdraw", { method, amount: amount.toString(), name, bank, account, details }) as CheckWithdrawsResponse;
}
export async function history() {
    return await command("history") as HistoryResponse;
}
export async function addPhoneToAccount(phone: string) {
    return await command("add-phone", { phone }) as loginResponse;
}
export async function addDocumentToAccount(documentType: string, document: string) {
    return await command("add-document", { documentType, document }) as loginResponse;
}
export async function addIdentityToAccount(name: string, surname: string) {
    return await command("add-identity", { name, surname }) as loginResponse;
}
export async function checkUser() {
    var response = await command("check-user") as loginResponse;
    if (response.session) {
        sessionId = response.session;
    }
    return response;
}
export async function cancelWithdraw() {
    return await command("cancel-withdraw");
}
export async function cancelRecharge() {
    return await command("cancel-recharge");
}
export async function getMercadoPagoPreference(product: number, card: number, amount: number, details = "") {
    return await command("mp-get-preference", { product: product.toString(), card: card.toString(), amount: amount.toString(), details }) as MercadoPagoPreferenceResponse;
}
export async function getMercadoPagoRechargePreference(amount: number) {
    return await command("mp-get-preference", { amount: amount.toString() }) as MercadoPagoPreferenceResponse;
}
export async function verifyMercadoPagoOp(id: string) {
    return await command("mp-verify", { id }) as loginResponse;
}
export async function getPurchaseMethods() {
    return await command("purchase-methods") as PurchaseMethodsResponse;
}
export async function getNiubizSessionToken(product: number, card: number, amount: number, details = "") {
    return await command("niubiz-get-token", { product: product.toString(), card: card.toString(), amount: amount.toString(), details }) as NibuizTokenResponse;
}
export async function getKushkiCartID(product: number, card: number, amount: number, details = "") {
    return await command("kushki-get-cart-id", { product: product.toString(), card: card.toString(), amount: amount.toString(), details }) as KushkiCartIDResponse;
}
export async function getDocumentTypes() {
    return await command("document-types") as DocumentTypesResponse;
}
export async function getBillingAddress() {
    return await command("billing-address") as BillingAddressResponse;
}

export async function command(name: string, params?: { [key: string]: string }): Promise<{ success: boolean, [key: string]: any }> {
    var body = "";
    if (!params)
        params = {};
    params["command"] = name;
    params["timezone"] = timezone.toString();
    if (sessionId) {
        params["session"] = sessionId;
    }
    console.log(`→ ${name}`);
    let result = await request('post', endpoint, params);
    if (result.ok == false) {
        console.error(`${name} HTTP error: ${result.status} ${result.statusText}`);
        return { success: false, message: "Error de conexión con el servidor" };
    } else {
        try {
            let response: { success: boolean, logout?: boolean, [key: string]: any } = result.json();
            if (response.logout) {
                console.info(`${name} returns a forced logout`);
                forcedLogout = true;
            }
            if (response.error) {
                console.error(`${name} Returns an error: ${response.message}`);
            } else {
                let info = Object.assign({}, response);
                delete info.success;
                delete info.user;
                if (Object.keys(info).length == 1)
                    info = info[Object.keys(info)[0]];
                console.log(`← ${name}${response.success ? "":" Error"}:`, info);
            }
            return response;
        } catch (error) {
            console.error(`${name}: Invalid response`, result);
            return { success: false, message: "Error de conexión con el servidor" };
        }
    }
}