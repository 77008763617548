import * as backend from './backend';
import * as JSCookie from 'js-cookie';
import moment from 'moment';
import Flickity from 'flickity';
import anime, { AnimeInstance } from 'animejs';

const KUSHKI_TEST_ENVIRONMENT = false;
const KUSHKI_MERCHANT_ID = "20000000107715842000";
const KUSHKI_PUBLIC_KEY = "d192f6130970432aa6e3e741a8ad314f";

declare function mercadoPagoCheckout(preferenceId: string): any;
declare function require(path: string): string;

let loading: HTMLElement;
let scroller: HTMLElement;
let darkness: HTMLElement;
let bg: HTMLElement;
let pager: HTMLElement;
let links: HTMLElement;

let dialogs: NodeListOf<HTMLElement>;
let login: HTMLElement;
let register: HTMLElement;
let infoPage: HTMLElement;
let faqPage: HTMLElement;
let notificationsPage: HTMLElement;
let rememberPassword: HTMLElement;
let newPassword: HTMLElement;
let messageDialog: HTMLElement;
let notificationDialog: HTMLElement;
let teleingresoDialog: HTMLElement;
let pendingRecharge: HTMLElement;
let pendingWithdraw: HTMLElement;
let purchase: HTMLElement;
let purchaseMethod: HTMLElement;
let TOC: HTMLElement;
let POL: HTMLElement;
let TC: HTMLElement;
let completeProfile: HTMLElement;
let addPhoneDialog: HTMLElement;
let addDocumentDialog: HTMLElement;
let contactDialog: HTMLElement;
let withdrawDialog: HTMLElement;
let teleingresoWaiting: HTMLElement;
let teleingresoContents: HTMLIFrameElement;
let kushkiDialog: HTMLElement;
let billingAddressDialog: HTMLElement;

let tabs: NodeListOf<HTMLElement>;
let pages: NodeListOf<HTMLElement>;
let registerPages: NodeListOf<HTMLElement>;
let walletPages: NodeListOf<HTMLElement>;
let carousels: NodeListOf<HTMLElement>;

let currentRegisterPage: number;
let currentWalletPage: number;
let carouselObjects: { [id: string]: Flickity; } = {};
let currentTab: number;
let currentHomeTab: number;
let currentInfoTab: number;

let infoProducts: backend.Product[];
let products: backend.Product[];
let countries: backend.country[] = [];
let visibleCountryIndex: number[];
let purchaseMethods: backend.PaymentMethod[];

let selectedRechargeAmount: number;
let selectedWithdrawAmount: number;
let selectedCountry: number;
let selectedCurrency: number;

let directPurchase: boolean;
let purchaseProduct: backend.Product;
let purchaseAmount: number;
let totalPurchase: number;

let loginCode: string;
let reminderEmail: string;

let withdrawAmount: number;
let withdrawMethod: string;

let messageCallback: () => void;

let interval: number;
let selectedPurchaseMethod: string;
let loadedScripts = [];

let darknessHideAnimation: AnimeInstance;

export var session: { user?: backend.loginResponse } = {}

function locationHashChanged() {
	var  url = new URL(location.toString());
	if (url.hash.startsWith("#mercadopago"))
		url = new URL(location.toString().replace('/#mercadopago', '/mercadopago'));
	console.log("Checking location", url);
	if (url.pathname.startsWith("/mercadopago")) {
		const paymentId = url.searchParams.get('payment_id');
		if (paymentId) {
			backend.verifyMercadoPagoOp(paymentId).then(result => {
				if (result.success == false) {
					showMessage("ERROR", (result as backend.errorResponse).message);
				} else {
					session.user = result;
					updateUserInfo();
					if (result.message) {
						showMessage("OPERACIÓN COMPLETADA", result.message);
					}
				}
			});
		} else {
			showMessage("ERROR", "No se ha encontrado órden de pago");
			console.log(url.searchParams);
		}
	}
	if (url.pathname.startsWith("/TOC")) {
		showTOC(false);
	}
	if (url.pathname.startsWith("/POL")) {
		showPOL();
	}
	if (url.pathname.startsWith("/TC")) {
		showTC();
	}
}
window.onhashchange = locationHashChanged;

function hideLoadingIcon() {
    scroller.style.opacity = '1';
    loading.style.opacity = '0';
}

window.onload = () => {
	const url = new URL(window.location.toString())
	const action = url.searchParams.get("action");

	interval = setInterval(updateUserInBackground, 30000);

	loading              = document.getElementById('loading');
	scroller             = document.getElementById('scroller');
	infoPage             = document.getElementById('info');
	notificationsPage    = document.getElementById('notifications');
	faqPage              = document.getElementById('faq');
	darkness             = document.getElementById('darkness');
	bg                   = document.getElementById('bg');
	login                = document.getElementById('login');
	register             = document.getElementById('register');
	pager                = document.getElementById('pager');
	tabs                 = document.querySelectorAll('.pager-tab');
	pages                = document.querySelectorAll('.flex-page');
	purchase             = document.getElementById('purchase');
	purchaseMethod       = document.getElementById('purchase-method');
	rememberPassword     = document.getElementById('remember-password-dialog');
	newPassword          = document.getElementById('new-password-dialog');
	TOC                  = document.getElementById('TOC');
	POL                  = document.getElementById('POL');
	TC                   = document.getElementById('TC');
	completeProfile      = document.getElementById('complete-profile');
	addPhoneDialog       = document.getElementById('add-phone');
	addDocumentDialog    = document.getElementById('add-document');
	contactDialog        = document.getElementById('contact-dialog');
	kushkiDialog         = document.getElementById('kushki');
	billingAddressDialog = document.getElementById('billing-address-dialog');
	messageDialog        = document.getElementById('message');
	notificationDialog   = document.getElementById('notification');
	teleingresoDialog    = document.getElementById('teleingreso');
	pendingRecharge      = document.getElementById('pending-recharge');
	pendingWithdraw      = document.getElementById('pending-withdraw');
	withdrawDialog       = document.getElementById('withdraw-dialog');
	dialogs              = document.querySelectorAll('.dialog');
	registerPages        = document.querySelectorAll('.register-page');
	walletPages          = document.querySelectorAll('.wallet-page');
	carousels            = document.querySelectorAll('.main-carousel');
	links                = document.querySelector('#links');
	teleingresoContents  = <HTMLIFrameElement>(document.getElementById('teleingreso-contents'));
	teleingresoWaiting   = document.getElementById('teleingreso-waiting');

	for (let carousel of carousels) {
		carouselObjects[carousel.id] = new Flickity(carousel);
	}
	carouselObjects['country-list'].on("change", (index: number) => { 
		selectedCountry = visibleCountryIndex[index];
		updateCurrencies();
	});
	carouselObjects['currency-list'].on("change", (index: number) => {
		selectedCurrency = index;
	});

	var savedID = JSCookie.get('sessionID');
	if (savedID) {
		console.log("Trying session ID", savedID);
		backend.setSessionID(savedID);
		backend.command("check-user").then(result => {
			if (result.success) {
				let savedSession = result as backend.loginResponse;
				if (savedSession && savedSession.currency && savedSession.currency.code && savedSession.country) {
					console.log("Using saved session user", savedSession)
					session.user = savedSession;
					updateUserInfo();
					selectTab(0, hideLoadingIcon);
					locationHashChanged();
                    updateUserInBackground();
					return;
				} else {
                    console.log("Invalid saved session", savedSession);
                    hideLoadingIcon();
                }
			} else {
                console.log("Error checking last session user", result);
                hideLoadingIcon();
            }
		}).catch(error => {
			console.log("Error reusing previous session", error);
            hideLoadingIcon();
		});
	} else {
        hideLoadingIcon();
    }
	
	backend.countries().then(result => {
		countries = result;
		updateInfoCurrencies();
		updateInfoProducts();
	});

	infoPage.style.display = 'flex';

	if (action == "verify") {
		const code = url.searchParams.get("code");
		const email = url.searchParams.get("email");
		backend.login(email, "", code).then(result => {
			if (result.success) {
				console.log("User account verified", result.email);
				session.user = result;
				let rememberCheckbox = <HTMLInputElement>document.getElementById('remember-password');
				if (rememberCheckbox.checked) {
					JSCookie.set('sessionID', result.session, { sameSite: 'strict' });
				} else {
					JSCookie.remove('sessionID');
				}
				updateUserInfo();
				selectTab(0, hideDialogs);
				locationHashChanged();
                updateUserInBackground();
			} else {
				console.log("Login failed", result.message);
				showMessage("ERROR", result.message);
			}
		});
	} else if (action == "reminder") {
		const code = url.searchParams.get("code");
		const email = url.searchParams.get("email");
		loginCode = code;
		reminderEmail = email;
		showNewPasswordDialog();
	} else if (action == "message") {
		const message = url.searchParams.get("message");
		const title = url.searchParams.get("title");
		let info = url.searchParams.get("info");
        if (info) {
            info = JSON.parse(info);
        }
        showMessage(title, message, { info });
    }
}

function showLoginDialog() {
	let errorMessage = document.getElementById('login-error-message');
	errorMessage.style.visibility = 'hidden';
	showDialog(login);
}

function showTOC(registration: boolean) {
	document.getElementById('toc-registration').style.display = (registration ? 'box' : 'none');
	document.getElementById('toc-accept').style.display = (!registration ? 'box' : 'none');
	showDialog(TOC);
}

function showPOL() {
	showDialog(POL);
}

function showTC() {
    showDialog(TC);
}

function showRememberPasswordDialog() {
	showDialog(rememberPassword);
}

function showNewPasswordDialog() {
	showDialog(newPassword);
}

function showPendingRechargeDialog(amount: string, instructions: string, code: string, account: string) {
	const amountInsert = `${amount}${session.user.currency.symbol}`;
	if (!instructions) {
		instructions = "";
	}
	instructions = instructions.replace("[amount]", amountInsert);
	pendingRecharge.querySelector('.data-recharge-amount').textContent = amount;
	pendingRecharge.querySelector('.data-recharge-account').textContent = account;
	pendingRecharge.querySelector('.data-recharge-instructions').textContent = instructions;
	pendingRecharge.querySelector('.data-recharge-code').textContent = code;
	showDialog(pendingRecharge);
}

function showPendingWithdrawDialog(amount: string, instructions: string) {
	const amountInsert = `${amount}${session.user.currency.symbol}`;
	instructions = instructions.replace("[amount]", amountInsert);
	pendingWithdraw.querySelector('.data-withdraw-amount').textContent = amount;
	pendingWithdraw.querySelector('.data-withdraw-instructions').textContent = instructions;
	showDialog(pendingWithdraw);
}

function showPurchaseDialog(product: backend.Product, card: number) {
	let conversion = product.conversion;
	let basePrice = card * conversion;
	let comission = basePrice * product.plus / 100;
	let total = basePrice + comission;
	let currency = session.user.currency.symbol;
	let title = document.getElementById('purchase-title');
	let confirmButton = document.getElementById('confirm-purchase-button');
    let extraInfo = <HTMLElement>purchase.querySelector('.data-product-extra-info-title');
    let wallet = Math.min(total, session.user.wallet + 0);
    let remaining = total - wallet;

    directPurchase = remaining > 0;
	purchaseProduct = product;
	purchaseAmount = card;
	totalPurchase = (directPurchase ? remaining : total);
	console.log(`Purchasing ${product.name} for ${total}${currency} (comission: ${comission})`, product);

	(<HTMLImageElement>purchase.querySelector('.data-product-image')).src = product.imageURL;
	(<HTMLElement>purchase.querySelector('.data-product-card-price')).textContent = `${product.currency.symbol}${card.toFixed(0)}`;
	(<HTMLElement>purchase.querySelector('.data-product-base-price')).textContent = `${currency}${basePrice.toFixed(2)}`;
	(<HTMLElement>purchase.querySelector('.data-product-comission')).textContent = `${currency}${comission.toFixed(2)}`;
	(<HTMLElement>purchase.querySelector('.data-product-total')).textContent = `${currency}${total.toFixed(2)}`;
	(<HTMLElement>purchase.querySelector('.data-product-wallet')).textContent = `${currency}${wallet.toFixed(2)}`;
	(<HTMLElement>purchase.querySelector('.data-product-remaining')).textContent = `${currency}${remaining.toFixed(2)}`;
	
    extraInfo.textContent = `${product.extraInfoTitle || ''}`;
    extraInfo.style.display = product.extraInfoTitle ? "block" : "none";

	if (wallet < total && currentHomeTab == 0) {
		title.textContent = "COMPRAR";
		confirmButton.textContent = "COMPRAR";
		document.getElementById('without-phone').style.display = 'none';
		document.getElementById('with-phone').style.display = 'block';
		document.getElementById('direct-purchase-wallet').style.display = (wallet > 0 ? 'flex' : 'none');
		document.getElementById('direct-purchase').style.display = 'flex';
    } else if (wallet < total) {
		title.textContent = "COMPRAR";
		confirmButton.textContent = "COMPRAR";
		document.getElementById('without-phone').style.display = 'none';
		document.getElementById('with-phone').style.display = 'none';
		document.getElementById('direct-purchase-wallet').style.display = (wallet > 0 ? 'flex' : 'none');
		document.getElementById('direct-purchase').style.display = 'flex';
	} else if (currentHomeTab == 0) {
		title.textContent = "CONFIRMAR COMPRA";
		confirmButton.textContent = "CONFIRMAR";
		document.getElementById('without-phone').style.display = 'none';
		document.getElementById('with-phone').style.display = 'block';
		document.getElementById('direct-purchase-wallet').style.display = 'none';
		document.getElementById('direct-purchase').style.display = 'none';
	} else {
		title.textContent = "CONFIRMAR COMPRA";
		confirmButton.textContent = "CONFIRMAR";
		document.getElementById('without-phone').style.display = 'block';
		document.getElementById('with-phone').style.display = 'none';
		document.getElementById('direct-purchase-wallet').style.display = 'none';
		document.getElementById('direct-purchase').style.display = 'none';
	}

	hideErrorBubble('recharge');
	showDialog(purchase);
}

function checkNiubizClosure() {
    let iframe = document.getElementById('visaNetJS');
    if (iframe.style.display == 'none') {
        window.location.reload();
    } else {
        setTimeout(checkNiubizClosure, 500);
    }
}
function handleNiubizClosure() {
    hideDialogs();
    setTimeout(checkNiubizClosure, 500);
}

function confirmPurchaseMethod() {
    let phone = (<HTMLInputElement>document.getElementById('recharge-phone')).value;
    let currencySymbol = session.user.currency.symbol;
    if (currentHomeTab != 0) {
        phone = "";
    }

    let purchase = {
        method: purchaseMethod,
        product: purchaseProduct.id,
        name: purchaseProduct.name,
        card: purchaseAmount,
        total: totalPurchase,
        user: session.user.email,
        country: session.user.country,
        currency: session.user.currency.code,
        currencySymbol,
        phone
    };
    console.log("Purchasing", purchase);

    let info = purchaseMethods.find(n => n.id == selectedPurchaseMethod);
    if (info && info.staticURL && !loadedScripts.includes(info.staticURL)) {
        let script = document.createElement("script");
        script.src = info.staticURL;
        document.body.appendChild(script);
        loadedScripts.push(info.staticURL);
        console.log("External script loaded", info.staticURL);
    }

    switch (selectedPurchaseMethod.toLowerCase()) {
        case "mercadopago":
            backend.getMercadoPagoPreference(purchase.product, purchase.card, purchase.total, purchase.phone).then(r => {
                if (r.success == false || !r.id) {
                    showMessage("ERROR", r.message || "El servicio no está disponible temporalmente.\n\nPor favor, inténtelo más tarde.");
                } else {
                    console.log(`MercadoPago preference for ${purchase.product} ${purchase.currencySymbol}${purchase.total}: ${r.id}`);
                    mercadoPagoCheckout(r.id);
                }
            });
            break;

        case "kushki":
            backend.getBillingAddress().then(r => {
                let addressInput  = (document.getElementById('billing-address') as HTMLInputElement);
                let cityInput     = (document.getElementById('billing-city') as HTMLInputElement);
                let regionInput   = (document.getElementById('billing-region') as HTMLInputElement);
                let zipInput      = (document.getElementById('billing-zip') as HTMLInputElement);
                let rememberInput = (document.getElementById('remember-billing-address') as HTMLInputElement);
                if (r.success) {
                    addressInput.value    = r.address;
                    cityInput.value       = r.city;
                    regionInput.value     = r.region;
                    zipInput.value        = r.zip;
                    rememberInput.checked = true;
                } else {
                    addressInput.value    = "";
                    cityInput.value       = "";
                    regionInput.value     = "";
                    zipInput.value        = "";
                    rememberInput.checked = false;
                }

                let countryInput = document.getElementById('billing-country') as HTMLInputElement;
                countryInput.disabled = true;
                countryInput.value = session.user.country.name.toUpperCase();
                showDialog(billingAddressDialog);
                global.addressFilled = () => {
                    let address  = (document.getElementById('billing-address') as HTMLInputElement).value;
                    let city     = (document.getElementById('billing-city') as HTMLInputElement).value;
                    let region   = (document.getElementById('billing-region') as HTMLInputElement).value;
                    let zip      = (document.getElementById('billing-zip') as HTMLInputElement).value;
                    let remember = (document.getElementById('remember-billing-address') as HTMLInputElement).checked;
                    if (!address) {
                        showErrorBubble('billing-address', 'Debe introducir una dirección');
                    } else if (!city) {
                        showErrorBubble('billing-address', 'Debe introducir una ciudad');
                    } else if (!region) {
                        showErrorBubble('billing-address', 'Debe introducir una región');
                    } else if (!zip) {
                        showErrorBubble('billing-address', 'Debe introducir un código postal');
                    } else {
                        let details = JSON.stringify({ 
                            referrer: window.location.origin,
                            address,
                            city,
                            region,
                            zip,
                            country: countryInput.value,
                            rememberAddress: remember
                        });
                        backend.getKushkiCartID(purchase.product, purchase.card, purchase.total, details).then(r => {
                            if (r.success == false || !r.cartID) {
                                showMessage("ERROR", r.message || "El servicio no está disponible temporalmente.\n\nPor favor, inténtelo más tarde.");
                                return;
                            }

                            console.log("Cart ID acquired for Kushki purchase:", r.cartID);
                            let form = document.getElementById('kushki-form') as HTMLFormElement;
                            form.action = `${backend.baseurl}/kushki`;
                            form.method = "post";
                            form.id = "kushki-form";
                            let hidden = document.getElementById('kushki-cart-id') as HTMLInputElement;
                            hidden.value = r.cartID.toString();
                            form.appendChild(hidden);
                            let iframe = form.querySelector('iframe');
                            if (iframe) { iframe.remove(); }
                            showDialog(kushkiDialog);

                            console.log("Creating kushki checkout, public key", KUSHKI_PUBLIC_KEY, "testing", KUSHKI_TEST_ENVIRONMENT);
							console.log("Using merchant ID", KUSHKI_PUBLIC_KEY, "currency", session.user.currency.code.toUpperCase());
                            var kushki = new KushkiCheckout({
                                form:              "kushki-form",
                                merchant_id:       KUSHKI_PUBLIC_KEY,
                                amount:            purchase.total.toString(),
                                currency:          session.user.currency.code.toUpperCase(),
                                payment_methods:   ["credit-card"],
                                inTestEnvironment: KUSHKI_TEST_ENVIRONMENT,
                            });
                        });
                    }
                }
            });
            return;

        case "niubiz":
            backend.getNiubizSessionToken(purchase.product, purchase.card, purchase.total).then(r => {
                if (r.success == false || !r.token) {
                    showMessage("ERROR", r.message || "El servicio no está disponible temporalmente.\n\nPor favor, inténtelo más tarde.");
                } else {
                    console.log("Session token acquired for Niubiz purchase:", r);
                    let now = new Date().getTime();
                    console.log("Niubiz token expiration date in", (r.token.expirationTime - now) / 60, "minutes");

                    showMessage("Niubiz", "Pulse el botón siguiente para iniciar el proceso de pago", {
                        hideOk: true,
                        showTOC: true,
                    });

                    let form = document.createElement('form');
                    form.action = `${backend.baseurl}/niubiz?session_id=${backend.getSessionID()}&purchase=${r.purchase}&merchant=${r.merchantId}&amount=${r.amount}&referrer=${encodeURIComponent(window.location.origin)}` ;
                    form.method = "post";
                    form.target = "niubiz-contents";
                    form.addEventListener('click', handleNiubizClosure);
                    let script = document.createElement('script');
                    script.setAttribute('src', r.staticURL);
                    script.setAttribute('data-sessiontoken', r.token.sessionKey);
                    script.setAttribute('data-channel', "web");
                    script.setAttribute('data-merchantid', r.merchantId);
                    script.setAttribute('data-merchantlogo', backend.baseurl + require('./images/LogoNiubiz.png'));
                    script.setAttribute('data-purchasenumber', r.purchase.toString());
                    script.setAttribute('data-amount', purchase.total.toString());
                    script.setAttribute('data-expirationminutes', "20");
                    script.setAttribute('data-timeouturl', "about:blank");
                    script.setAttribute('data-formbuttoncolor', "#000000");
                    form.appendChild(script);

                    let buttons = <HTMLElement>messageDialog.querySelector('#buttons');
                    buttons.appendChild(form);
                }
            }).catch(error => {
                showMessage("ERROR", error);
            });
    }
    hideDialogs();
}

function confirmPurchase() {
    let phone: string;
    let confirm: string;

	hideErrorBubble('recharge');
    if (currentHomeTab == 0) {
		phone = (<HTMLInputElement>document.getElementById('recharge-phone')).value;
		confirm = (<HTMLInputElement>document.getElementById('recharge-phone-confirm')).value;
		if (!phone) {
			showErrorBubble('recharge', "Debe introducir un teléfono");
			return;
		} else if (!confirm) {
			showErrorBubble('recharge', "Debe confirmar el teléfono");
			return;
		} else if (phone != confirm) {
			showErrorBubble('recharge', "Los teléfonos no coinciden");
			return;
		} 
    }

	if (directPurchase) {
		console.log(`Direct purchase ${purchaseProduct.name} for ${purchaseAmount}: showing methods`);
		hideDialogs(() => {
            if (!session.user.name || !session.user.surname) {
                showMessage("Perfil incompleto", "Para poder realizar compras directas on-line, es necesario que completes primero tu perfil con tu nombre y apellidos.", {
                    callback: () => showDialog(completeProfile)
                });
                return;
            }
            if (!session.user.mobile) {
                showMessage("Perfil incompleto", "Para poder realizar compras directas on-line, es necesario que completes primero tu perfil con un número de teléfono de contacto.", {
                    callback: () => showDialog(addPhoneDialog)
                });
                return;
            }
            if (!session.user.document) {
                showMessage("Perfil incompleto", "Para poder realizar compras directas on-line, es necesario que completes primero tu perfil con un número de documento.", {
                    callback: showAddDocumentDialog
                });
                return;
            }
            backend.getPurchaseMethods().then(result => {
                purchaseMethods = result.paymentMethods;
                let methodsStrip = document.getElementById('purchase-method-list');
                let methods = [];
                for (var n of purchaseMethods) {
                    methods.push({ method: n.name, imageURL: n.imageURL });
                }
                console.log("Received methods", methods, "from", result);
                if (methods.length == 0) {
                    showMessage("Error", "En estos momentos no hay ningún método de pago activo para formalizar su compra directa. Recargue su monedero para terminar la operación.");
                } else {
                    populateWithElements(methodsStrip, methods, ["method", "imageURL"]);
                    methods.forEach(n => document.querySelector('.method-'+n.method).addEventListener('click', () => selectPurchaseMethod(n.method)));
                    selectPurchaseMethod(methods[0].method);
                    showDialog(purchaseMethod);
                }
            });
        });
    } else if (session.user.wallet < totalPurchase || !totalPurchase) {
		hideDialogs();
		selectTab(1);
		selectWalletPage(1);
	} else if (currentHomeTab == 0) {
        hideDialogs();
        backend.purchase(purchaseProduct.id, purchaseAmount, phone).then(r => { 
            if (r.success) {
                showMessage("COMPRA COMPLETADA", `Recibirás en breve un mensaje en el teléfono ${phone} con la confirmación de tu pedido.`);
                session.user.wallet = r.wallet;
                session.user.points = r.points;
                updateUserInfo();
            } else {
                showMessage("ERROR", r.message);
            }
        });
    } else {
		hideDialogs();
	}
}

function selectPurchaseMethod(method: string,) {
    console.log("Selecting purchase method", method);
    selectedPurchaseMethod = method;
    document.querySelectorAll('.purchase-method-item').forEach(n => {
        let mark = n.querySelector('.mark') as HTMLElement;
        let image = n.querySelector('.data-imageURL') as HTMLElement;
        if (n.classList.contains(`method-${method}`)) {
            mark.style.visibility = "visible";
            image.style.opacity = "1";
        } else {
            mark.style.visibility = "hidden";
            image.style.opacity = "0.5";
        }
    });
}

function sendReminder() {
	let email = (<HTMLInputElement>document.getElementById('reminder-email')).value;
	hideDialogs(() => backend.sendReminder(email).then(r => {
		if (r.success) {
			showMessage("LINK ENVIADO", `Recibirás en breve un mensaje en la dirección ${email} con tu nueva contraseña.`);
		} else {
			showMessage("ERROR", r.message);
		}
	}));
}

function sendContactMessage() {
	let subject = (<HTMLInputElement>document.getElementById('contact-subject')).value;
	let content = (<HTMLTextAreaElement>document.getElementById('contact-text')).value;
	hideDialogs(() => {
		backend.sendContactMessage(subject, content).then(r => {
			if (r.success) {
				showMessage("MENSAJE ENVIADO", `¡Gracias por tu colaboración!`);
			} else {
				showMessage("ERROR", r.message);
			}

		});
	});
}

function showAddDocumentDialog() {
    backend.getDocumentTypes().then(r => {
        if (r.success) {
            let select = document.getElementById('document-type') as HTMLSelectElement;
            for (var n = select.childElementCount-1; n >= 0; n--) {
                select.removeChild(select.childNodes[n]);
            }
            for (var row of r.documentTypes) {
                let option = document.createElement('option');
                option.value = row.id;
                option.innerText = row.name;
                select.appendChild(option);
            }
            showDialog(addDocumentDialog);
        }
    });
}

function showRegisterDialog() { 
	updateCountries();
	currentRegisterPage = undefined; 
	selectRegisterPage(0); 
	showDialog(register); 
}

function showDialog(dialog: HTMLElement) {
	if (anyDialogVisible()) {
		hideDialogs(() => showDialog(dialog), true);
	} else {
		disableScrolling();
		bg.style.display = 'flex'; 
        if (darknessHideAnimation) {
            darknessHideAnimation.pause();
            darknessHideAnimation = undefined;
        }
		if (darkness.style.visibility != 'visible') {
			darkness.style.visibility = 'visible'; 
		}
        anime({ targets: darkness, opacity: [0, 0.9], easing: 'linear', duration: 150 });
        dialog.style.display = 'flex';
		anime({ targets: dialog, scale: [0.25, 1], opacity: [0, 1], easing: 'easeOutElastic(1, .8)', duration: 600 });
	}
}

export function hideDialogs(callback?: () => void, keepBackground?: boolean) {
	if (!keepBackground) {
		enableScrolling();
        darknessHideAnimation = anime({ targets: darkness, opacity: 0, duration: 200, complete: () => { 
            darkness.style.visibility = 'hidden'; 
            darknessHideAnimation = undefined;
        } });
	}
	let found = false;
	for (let dialog of dialogs) {
		let item = <HTMLElement>dialog;
		if (item.style.display == 'flex') {
			anime({ targets: item, scale: 0.75, opacity: 0, easing: 'easeOutQuad', duration: 125, complete: () => { 
				item.style.display = 'none';
				bg.style.display = 'none';
				if (callback) {
					callback();
					callback = undefined;
				}
			} });
			found = true;
		}
	}
	if (callback && !found) {
		callback();
	}

	if (backend.forcedLogout) {
		console.log("Forced logout");
		JSCookie.remove("sessionID");
		session = {};
		showTab(undefined);
	}
}

function anyDialogVisible() {
	for (let dialog of dialogs) {
		if (dialog.style.display == 'flex') {
			return true;
		}
	}
	return false;
}

function disableScrolling(){
	var x=window.scrollX;
	var y=window.scrollY;
	window.onscroll=function(){window.scrollTo(x, y);};
	document.body.addEventListener('touchmove', preventDefault, { passive: false });
}

function enableScrolling(){
	window.onscroll=function(){};
	document.body.removeEventListener('touchmove', preventDefault);
}

function preventDefault(e: Event) {
	e.preventDefault();
}

function showTab(index: number) {
	if (index != undefined) {
		infoPage.style.display = 'none';
		pager.style.display = 'flex';
		links.style.display = 'flex';
	} else {
		infoPage.style.display = 'flex';
		pager.style.display = 'none';
		links.style.display = 'none';
	}
	for (let n = 0; n < Math.max(tabs.length, pages.length); n++) {
		if (n == index) {
			if (pages.length > n) {
				pages[n].style.display = 'flex';
			}
			if (tabs.length > n) {
				tabs[n].classList.add('pager-selected');
			}
		} else {
			if (pages.length > n) {
				pages[n].style.display = 'none';
			}
			if (tabs.length > n) {
				tabs[n].classList.remove('pager-selected');
			}
		}
	}
	currentTab = index;
}

function selectTab(index: number, callback?: () => void) {	
	switch (index) {
		case 0:
			updateProducts(() => {
				pager.style.backgroundColor = "#0CEB8D";
				showTab(index);
				if (callback) callback();
			});
			break;
		case 1:
			selectWalletButton(undefined);
			selectWalletPage(0);
			pager.style.backgroundColor = "#06D7ED";
			showTab(index);
			if (callback) callback();
			break;
		case 2:
			updatePointsHistory(() => {
				pager.style.backgroundColor = "#F24B8B";
				showTab(index);
				if (callback) callback();
			});
			break;
		case 3:
			updateUser(() => {
				pager.style.backgroundColor = "#F5D332";
				showTab(index);
				if (callback) callback();
			});
			break;
		case 4:
			updateNotifications(() => showTab(index));
			if (callback) callback();
			break;
		case 5:
			updateFAQ(() => showTab(index));
			if (callback) callback();
			break;
	}
}

function hideWalletPage() {
	if (currentWalletPage != -1) {
		currentWalletPage = -1;
		for (let n = 0; n < walletPages.length; n++) {
			walletPages[n].style.display = 'none';
		}
	}
}

function showWalletPage(index: number) {
	if (index >= walletPages.length) {
		index = walletPages.length-1;
	}
	if (index < 0) {
		index = 0;
	}
	if (currentWalletPage != index) {
		for (let n = 0; n < walletPages.length; n++) {
			walletPages[n].style.display = (n == index ? 'flex':'none');
		}
		currentWalletPage = index;
	}
}

function selectWalletPage(index: number) {
	switch (index) {
		case 1:
			updateRecharges(() => showWalletPage(index));
			break;
		case 2:
			updateWithdraws(() => showWalletPage(index));
			break;
		case 3:
			updateWalletHistory(() => showWalletPage(index));
			break;
		default:
			showWalletPage(index);
			break;
	}
}

function validateEmail(mail: string) {
    return (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail));
}

function showRegisterError(message: string) {
	const headShake = [
		{ translateX: 0 },
		{ translateX: -12, rotateZ: -4.5 },
		{ translateX: 10, rotateZ: 3.5 },
		{ translateX: -6, rotateZ: -2.5 },
		{ translateX: 4, rotateZ: 1.5 },
		{ translateX: 0, rotateZ: 0 },
	];
	anime({
		targets: '#bg', keyframes: headShake, duration: 500, easing: 'easeOutQuad', complete: () => {
			let errorText = document.getElementById('register-error-text');
			let errorMessage = document.getElementById('register-error-message');
			errorMessage.style.visibility = 'visible';
			errorText.textContent = message;
			anime({ targets: '#register-error-message', scale: [0.3, 1], duration: 500 });
		}
	});
}

function performRegister() {
	hideDialogs();

	let email = (<HTMLInputElement>document.getElementById('register-email')).value;
	let password = (<HTMLInputElement>document.getElementById('register-password')).value;
	let country = countries[selectedCountry].code;
	let currency = countries[selectedCountry].currencies[selectedCurrency].code;
	backend.command("register", { email, password, currency, country }).then(result => {
		showMessage(result.success ? "REGISTRO COMPLETADO" : "ERROR", result.message);
	});
}

function performAddDocument() {
    let select = <HTMLSelectElement>document.getElementById('document-type');
    let documentType = select.options[select.selectedIndex].value;
    let documentNumber = (<HTMLInputElement>document.getElementById('document')).value;
	if (!documentType) {
		showErrorBubble('add-document', 'Debe seleccionar un tipo de documento');
	} else if (!documentNumber) {
		showErrorBubble('add-document', 'Debe introducir un número de documento');
	} else {
		hideDialogs(() => {
			backend.addDocumentToAccount(documentType, documentNumber).then(result => {
				if (result.success) {
					session.user = result;
					updateUserInfo();
					showMessage("PERFIL ACTUALIZADO", "¡Gracias por tu confianza!");
				} else {
					showMessage("ERROR", result.message);
				}
			});
		});
    }
}

function performAddPhone() {
	let mobile = (<HTMLInputElement>document.getElementById('new-mobile')).value;
	let repeat = (<HTMLInputElement>document.getElementById('new-mobile-repeat')).value;
	if (!mobile) {
		showErrorBubble('add-phone', 'Debe introducir un número de teléfono');
	} else if (!repeat || repeat != mobile) {
		showErrorBubble('add-phone', 'Los números de teléfono no coinciden');
	} else {
		hideDialogs(() => {
			backend.addPhoneToAccount(mobile).then(result => {
				if (result.success) {
					session.user = result;
					updateUserInfo();
					showMessage("PERFIL ACTUALIZADO", "¡Gracias por tu confianza!");
				} else {
					showMessage("ERROR", result.message);
				}
			});
		});
	}
}

function performCancelRecharge() {
	hideDialogs(() => {
		backend.cancelRecharge().then(result => {
			if (result.success) {
				showMessage("RECARGA CANCELADA", "Su recarga pendiente ha sido cancelada.");
			} else {
				showMessage("ERROR", result.message);
			}
		})
	});
}

function performCancelWithdraw() {
	hideDialogs(() => {
		backend.cancelWithdraw().then(result => {
			if (result.success) {
				showMessage("SOLICITUD CANCELADA", "Su solicitud de retirada ha sido cancelada.");
			} else {
				showMessage("ERROR", result.message);
			}
		})
	});
}

function performCompleteProfile() {
	let name = (<HTMLInputElement>document.getElementById('new-name')).value;
	let surname = (<HTMLInputElement>document.getElementById('new-surname')).value;
	if (!name) {
		showErrorBubble('complete-profile', 'Debe introducir un nombre');
	} else if (!surname) {
		showErrorBubble('complete-profile', 'Debe introducir al menos un apellido');
	} else {
		hideDialogs(() => {
			backend.addIdentityToAccount(name, surname).then(result => {
				if (result.success) {
					session.user = result;
					updateUserInfo();
					showMessage("PERFIL ACTUALIZADO", "¡Gracias por tu confianza!");
				} else {
					showMessage("ERROR", result.message);
				}
			});
		});
	}
}

function performNewPassword() {
	let password = (<HTMLInputElement>document.getElementById('new-password')).value;
	let confirm = (<HTMLInputElement>document.getElementById('confirm-new-password')).value;
	hideDialogs(() => {
		if (!reminderEmail) {
			showMessage("ERROR", "Debe introducir un e-mail");
		} else if (!validateEmail(reminderEmail)) {
			showMessage("ERROR", "El e-mail introducido no es válido");
		} else if (!password) {
			showMessage("ERROR", "Debe introducir una contraseña", { callback: showNewPasswordDialog });
		} else if (!confirm) {
			showMessage("ERROR", "Debe confirmar la contraseña", { callback: showNewPasswordDialog });
		} else if (confirm !== password) {
			showMessage("ERROR", "Las contraseñas no coinciden", { callback: showNewPasswordDialog });
		} else {
			backend.changePassword(reminderEmail, password, loginCode).then(r => {
				if (r.success) {
					showMessage("NUEVA CONTRASEÑA", r.message);
				} else {
					showMessage("ERROR", r.message);
				}
			});
		}
	});
}

function validateRegisterForm() {
	let email = (<HTMLInputElement>document.getElementById('register-email')).value;
	let password = (<HTMLInputElement>document.getElementById('register-password')).value;
	let confirm = (<HTMLInputElement>document.getElementById('register-confirm-password')).value;
	let error;
	if (!email) {
		showRegisterError("Debe introducir un e-mail");
	} else if (!validateEmail(email)) {
		showRegisterError("El e-mail introducido no es válido");
	} else if (!password) {
		showRegisterError("Debe introducir una contraseña");
	} else if (!confirm) {
		showRegisterError("Debe confirmar la contraseña");
	} else if (confirm != password) {
		showRegisterError("Las contraseñas no coinciden");
	} else {
		return true;
	}
	return false;
}

function selectRegisterPage(index: number) {
	document.getElementById('register-error-message').style.visibility = 'hidden';
	if (index >= registerPages.length + 1) { index = registerPages.length; }
	if (index < 0) { index = 0; }

	if (currentRegisterPage == index) {
		return;
	} else if (currentRegisterPage == 0 && !validateRegisterForm()) {
		return;
	} else if (index == registerPages.length) {
		showTOC(true);
		return;
	}
	for (let n = 0; n < registerPages.length; n++) {
		let page = registerPages[n];
		let isVisible = page.style.visibility == 'visible';
		anime.remove(page);
		page.style.position = 'relative';
		if (isVisible && n == currentRegisterPage) {
			anime({ targets: page, left: n < index ? '-800px':'800px', easing: 'easeOutExpo', duration: 1000, complete: () => {
				registerPages[n].style.visibility = 'hidden';
			}});
		} else if (n == index) {
			page.style.visibility = 'visible';
			if (currentRegisterPage != undefined) {
				anime({ targets: page, left: [ currentRegisterPage > index ? '-800px':'800px', '0px' ], easing: 'easeOutExpo', duration: 1000 });
			} else {
				page.style.left = '0px';
			}
		} else {
			page.style.visibility = 'hidden';
		}
	}
	currentRegisterPage = index;
	let next = <HTMLButtonElement>document.querySelector('#register-next');
	let prev = <HTMLButtonElement>document.querySelector('#register-prev');
	next.disabled = false; //index == registerPages.length-1;
	prev.disabled = index == 0;

	for (let carousel in carouselObjects) {
		carouselObjects[carousel].resize();
	}
}

function selectStripButton(page: string, color: string, index: number) {
	let buttons = document.querySelectorAll(`#${page}-button-strip > .big`);
	let className = `selected-${color}`;
	if (index >= buttons.length || index < 0) {
		index = undefined;
	}
	for (let n = 0; n < buttons.length; n++) {
		if (n == index) {
			buttons[n].classList.add(className);
		} else {
			buttons[n].classList.remove(className);
		}
	}
	return index;
}

function selectHomeButton(index: number) {
	currentHomeTab = selectStripButton('home', 'green', index);
	fillProducts();
}

function selectInfoButton(index: number) {
	currentInfoTab = selectStripButton('info', 'green', index);
	fillInfoProducts();
}

function selectProfileButton(index: number) {
	selectStripButton('profile', 'yellow', index);
}

function selectWalletButton(index: number) { 
	selectStripButton('wallet', 'blue', index); 
	switch (index) {
		case 0: selectWalletPage(1); break;
		case 1: selectWalletPage(2); break;
		case 2: selectWalletPage(3); break;
	}
}

function selectRechargeAmount(index: number, amount?: number) {
	selectStripButton('recharge', 'blue', index);
	selectedRechargeAmount = amount;
	global.selectedRechargeAmount = amount;
	for (let button of document.querySelectorAll('.recharge-method-button')) {
		(<HTMLButtonElement>button).disabled = (amount == undefined);
	}
}

function selectWithdrawAmount(index: number, amount?: number) {
	selectStripButton('withdraw', 'blue', index);
	selectedWithdrawAmount = amount;
	for (let button of document.querySelectorAll('.withdraw-method-button')) {
		(<HTMLButtonElement>button).disabled = (amount == undefined);
	}
}

function clearElements(container: HTMLElement) {
	populateWithElements(container, [], []);
}

function populateWithElements(container: HTMLElement, elements: any[], fields: string[], filters?: { [field: string]: (arg0: string) => string; }, html?: boolean) {
	console.log(`Populating ${container.id} with ${elements.length} elements`);
	let template = <HTMLElement>container.children[0];
    let index = 0;
	for (let n = container.children.length - 1; n > 0; n--) {
		container.removeChild(container.children[n]);
	}
	for (let element of elements) {
		let child = <HTMLElement>template.cloneNode(true);
		child.classList.remove('template');
        child.classList.add(`row${index++}`);
		container.appendChild(child);
		for (let key of fields) {
            let value = element[key];
            if (filters && filters[key]) {
                value = filters[key](value);
            } else if (key == 'date') {
                let date = moment(value);
                if (date.isSame(moment.now(), 'day'))
                    value = date.format('H:mm');
                else
                    value = date.format('MMM D');
            }

            let classSelector = `.class-${key}`;
            if (child.matches(classSelector)) {
                child.classList.add(key + "-" + value);
            }
			child.querySelectorAll(classSelector).forEach(obj =>
                obj.classList.add(key + "-" + value));

            let containers = child.querySelectorAll(`.data-${key}`);
			for (let obj of containers) {
				if (key == 'imageURL') {
					(<HTMLImageElement>obj).src = value;
				} else if (html) {
					obj.innerHTML = value;
				} else {
					obj.textContent = value;
				}
			}
		}
	}
}

function fillInfoProducts() {
	let tabNames = ['recharge', 'cards', 'commerce'];
	if (currentInfoTab == undefined) {
		selectInfoButton(0);
	}

	let info = document.getElementById('info-products');
	var total = 0;
	info.querySelectorAll('.cardlist').forEach(n => n.remove());
	for (let product of infoProducts) {
		if (product.tab != tabNames[currentInfoTab]) {
			continue;
		}
		let container = info.appendChild(document.createElement('div'));
		container.classList.add('cardlist');

		// Title
		let title = container.appendChild(document.createElement('div'));
		title.classList.add('title');
		title.style.background = `#${product.titleColor || product.color}`;
		if (product.imageURL) {
			let img = title.appendChild(document.createElement('img'));
			img.src = product.imageURL;
		} else {
			title.textContent = product.name;
		}

		// Contents
		let contents = container.appendChild(document.createElement('div'));
		contents.classList.add('content');
		product.cards.forEach(card => {
			let div = contents.appendChild(document.createElement('div'));
			div.classList.add('card');
			div.addEventListener("click", () => showLoginDialog());			
			let span = div.appendChild(document.createElement('span'));
			span.textContent = `${product.currency.symbol}${card.toFixed(0)}`;
		});
		total++;
	}
	if (total == 0) {
		let container = info.appendChild(document.createElement('div'));
		container.classList.add('cardlist');
		container.classList.add('flex-vertical');
		let div = container.appendChild(document.createElement('div'));
		div.style.marginTop = '100px';
		let span = div.appendChild(document.createElement('span'));
		span.textContent = "No se encontraron productos en la categoría";
	}
}

function fillProducts() {
	let tabNames = ['recharge', 'cards', 'commerce'];
	if (currentHomeTab == undefined) {
		selectHomeButton(0);
	}

	var total = 0;
	let home = document.getElementById('home');
	home.querySelectorAll('.cardlist').forEach(n => n.remove());
	for (let product of products) {
		if (product.tab != tabNames[currentHomeTab]) {
			continue;
		}
		let container = home.appendChild(document.createElement('div'));
		container.classList.add('cardlist');

		// Title
		let title = container.appendChild(document.createElement('div'));
		title.classList.add('title');
		title.style.background = `#${product.color}`;
		if (product.imageURL) {
			let img = title.appendChild(document.createElement('img'));
			img.src = product.imageURL;
		} else {
			title.textContent = product.name;
		}

		// Contents
		let contents = container.appendChild(document.createElement('div'));
		contents.classList.add('content');
		product.cards.forEach(card => {
			let div = contents.appendChild(document.createElement('div'));
			div.classList.add('card');
			div.addEventListener("click", () => showPurchaseDialog(product, card));			
			let span = div.appendChild(document.createElement('span'));
			span.textContent = `${product.currency.symbol}${card.toFixed(0)}`;
		});
		total++;
	}
	if (total == 0) {
		let container = home.appendChild(document.createElement('div'));
		container.classList.add('cardlist');
		container.classList.add('flex-vertical');
		let div = container.appendChild(document.createElement('div'));
		div.style.marginTop = '100px';
		let span = div.appendChild(document.createElement('span'));
		span.innerHTML = "No se encontraron<br>productos en la categoría";
	}
}

function updateInfoCurrencies() {
	let countrySelect = <HTMLSelectElement>document.getElementById('info-country');
	if (countrySelect.selectedIndex == -1) {
		countrySelect.selectedIndex = 0;
	}
	let country = countrySelect.options[countrySelect.selectedIndex].value;
	for (var c of countries) {
		if (c.code == country) {
			let currencySelect = <HTMLSelectElement>document.getElementById('info-currency');
			currencySelect.selectedIndex = -1;
			for (var i = currencySelect.length; i > 0 ; i--) {
				currencySelect.remove(0);
			}
			for (var currency of c.currencies) {
				var option = document.createElement('option');
				option.value = currency.code;
				option.innerHTML = currency.plural;
				if (currency.code == c.currencies[0].code) {
					option.selected = true;
				}
				currencySelect.appendChild(option);
			}
		}
	}
}

function showInfoProducts() {
	let infoProducts = <HTMLDivElement>document.getElementById('info-products');
	let infoActions = <HTMLDivElement>document.getElementById('info-actions');
	infoProducts.style.display = 'flex';
	infoActions.style.display = 'none';
}

function updateInfoProducts() {
	let countrySelect = <HTMLSelectElement>document.getElementById('info-country');
	let currencySelect = <HTMLSelectElement>document.getElementById('info-currency');
	if (countrySelect.selectedIndex == -1) {
		countrySelect.selectedIndex = 0;
	}
	let country = countrySelect.options[countrySelect.selectedIndex].value;
	let currency = currencySelect.options[currencySelect.selectedIndex].value;
	backend.products(country, currency).then(result => {
		infoProducts = result;
		fillInfoProducts();
	});
}

function updateProducts(callback: () => void) {
	backend.products(session.user.country.code, session.user.currency.code).then(result => {
		products = result;
		fillProducts();
		callback();
	})
}

function describeStatus(status: string) {
    switch (status) {
        case 'cancelled': return "CANCELADA";
        case 'error': return "OPERACIÓN RECHAZADA";
        case 'pending': return "EN CURSO";
        case 'fulfilled': return "COMPLETADA";
        case 'unpaid': return "PENDIENTE";
        default: return status;
    }
}

function updateWalletHistory(callback: () => void) {
	let historyContainer = document.getElementById('wallet-history-container');
	let currency = session.user.currency.symbol;
	backend.history().then(result => { 
		if (result.success) {
			let items: any[] = [];
			result.withdraws?.forEach(n => items.push({
				date: n.creation_date,
				action: "Retirada de fondos",
				status: n.fulfillment_date ? "COMPLETADA" : "EN CURSO",
				amount: `${currency}${parseFloat(n.amount).toFixed(2)}`
			}))
			result.recharges?.forEach(n => items.push({
				date: n.creation_date,
				action: "Ingreso en monedero",
				status: n.fulfillment_date ? "COMPLETADA" : "EN CURSO",
				amount: `${currency}${parseFloat(n.amount).toFixed(2)}`
			}));
			result.purchases?.forEach(n => {
				console.log("Product", n.product, result.products);
				let product = result.products.find(p => p.id == n.product);
				let amount = parseFloat(n.amount);
				items.push({
					date: n.creation_date,
					action: product ? product.name : "Compra de producto",
					status: n.fulfillment_date ? "COMPLETADA" :describeStatus(n.status),
					amount: `${currency}${amount.toFixed(2)}`
				});
			});
			items.sort((a, b) => b.date.localeCompare(a.date));
			populateWithElements(historyContainer, items, ['date', 'action', 'status', 'amount'])
			callback();
		}
	});
}

function exchangePoints() {
	backend.convertPoints().then(result => {
		if (result.success) {
			showMessage("Conversión finalizada", result.amount.toFixed(2) + session.user.currency.symbol + " han sido depositados en tu monedero. ¡Gracias por<br>tu confianza!");
			let historyContainer = document.getElementById('points-history');
			let pointsConversion = document.getElementById('points-conversion');
			let pointsZero = document.getElementById('points-zero');
			session.user.points = result.points;
			session.user.wallet = result.wallet;
			if (result.points > 0) {
				pointsZero.style.display = 'none';
				pointsConversion.style.display = 'box';
			} else {
				pointsZero.style.display = 'box';
				pointsConversion.style.display = 'none';
			}
			updateUserInfo();
			clearElements(historyContainer);
			populateWithElements(historyContainer, result.history, ['date', 'reason', 'points']);
		} else {
			showMessage("Error", result.message);
		}
	});
}

function updatePointsHistory(callback: () => void) {
	let historyContainer = document.getElementById('points-history');
	let pointsConversion = document.getElementById('points-conversion');
	let pointsZero = document.getElementById('points-zero');
	clearElements(historyContainer);
	backend.checkPoints().then(result => {
		if (result.success) {
			session.user.points = result.points;
			session.user.pointsConversionRate = result.rate;
			session.user.pointsConversionResult = result.conversion;
			if (result.points > 0) {
				pointsZero.style.display = 'none';
				pointsConversion.style.display = 'box';
			} else {
				pointsZero.style.display = 'box';
				pointsConversion.style.display = 'none';
			}
			updateUserInfo();
			populateWithElements(historyContainer, result.history, ['date', 'reason', 'points']);
			callback();
		}
	});
}

function teleingresoLoaded() {
	if (teleingresoWaiting) {
		console.log("Teleingreso IFrame loaded");
		teleingresoWaiting.style.display = "none";
		teleingresoContents.style.display = "block";
	} else {
		setTimeout(teleingresoLoaded, 500);
	}
}

function recharge(amount: number, method: string, details?: string) {

	if (method == "mercadopago") {
		backend.getMercadoPagoRechargePreference(amount).then(r => {
			if (r.success == false || !r.id) {
				showMessage("ERROR", r.message || "El servicio no está disponible temporalmente.\n\nPor favor, inténtelo más tarde.");
			} else {
				console.log(`MercadoPago preference for ${amount}${session.user.currency.symbol}: ${r.id}`);
				mercadoPagoCheckout(r.id);
			}
		});
		return;
	}

	hideWalletPage();

	backend.recharge(amount, method, details).then(result => {
		if (result.success) {
			session.user.wallet = result.wallet;
			session.user.points = result.points;
			updateRecharges(() => showWalletPage(0));
			if (method == "paypal") {
				showMessage("RECARGA COMPLETADA", "Los fondos están disponibles ya en tu monedero.");
			} else if (method == "teleingreso") {
				var recharge: backend.Recharge = result.pending[0];
				if (!recharge || recharge.payment_method != "teleingreso") {
					console.log("Expected pending teleingreso recharge not found", recharge);
					showMessage("ERROR", "Ha ocurrido un error procesando su petición. Por favor, inténtelo de nuevo más tarde.");
				} else {
					showDialog(teleingresoDialog);
					teleingresoWaiting.style.display = "block";
					teleingresoContents.style.display = "none";
					teleingresoContents.src = `https://teleingreso.es/iframe/?amount=${amount}&merchant=166&reference=${recharge.code}&lang=latam`;
				}
			}
			updateUserInfo();
		} else {
			showMessage("ERROR", result.message);
			showWalletPage(0);
		}
	});
}

function showWithdrawDialog(amount: number, method: string) {
	withdrawAmount = amount;
	withdrawMethod = method;
	hideErrorBubble('withdraw');
	showDialog(withdrawDialog);
}

function performWithdraw() {
	let name = (<HTMLInputElement>document.getElementById('withdraw-name')).value;
	let bank = (<HTMLInputElement>document.getElementById('withdraw-bank')).value;
	let account = (<HTMLInputElement>document.getElementById('withdraw-account')).value;
	let details = (<HTMLInputElement>document.getElementById('withdraw-details')).value;
	if (!name) {
		showErrorBubble('withdraw', 'Debe introducir un titular');
	} else if (!bank) {
		showErrorBubble('withdraw', 'Debe introducir un banco');
	} else if (!account) {
		showErrorBubble('withdraw', 'Debe introducir un número de cuenta');
	} else if (!account.replace(/[^0-9-]/, "").match(/^([0-9]{11})|([0-9]{2}-[0-9]{3}-[0-9]{6})$/)) {
		showErrorBubble('withdraw', 'El número de cuenta no es válido');
	} else {
		hideDialogs(() => {
			backend.withdraw(withdrawAmount, withdrawMethod, name, bank, account, details).then(result => {
				showWalletPage(1);
				if (result.success) {
					updateWithdraws(() => showWalletPage(1));
					updateUserInfo();
				} else {
					showMessage("ERROR", result.message);
				}
			});
		});
	}
}

function updateRecharges(callback: () => void) {
	backend.checkRecharges().then(r => {
		if (r.pending && r.pending.length > 0) {
			for (let recharge of r.pending) {
				if (recharge.payment_method == "teleingreso") {
					showDialog(teleingresoDialog);
					teleingresoWaiting.style.display = "block";
					teleingresoContents.style.display = "none";
					teleingresoContents.src = `https://teleingreso.es/iframe/?amount=${recharge.amount}&merchant=166&reference=${recharge.code}&lang=latam`;
				} else {
					showPendingRechargeDialog(parseFloat(recharge.amount).toFixed(2), recharge.instructions, recharge.code, recharge.account);
					return;
				}
			}
		}
		
		if (r.success) {
			let currency = session.user.currency.symbol;
			let currencyCode = session.user.currency.code.toUpperCase();
			let rechargeStrip = document.getElementById('recharge-button-strip');
			let methodsStrip = document.getElementById('recharge-method-button-strip');
			Array.from(rechargeStrip.children).forEach(n => n.remove());
			Array.from(methodsStrip.children).forEach(n => n.remove());
			r.recharges.forEach((amount, index) => {
				let button = rechargeStrip.appendChild(document.createElement('button'));
				button.classList.add('big');
				button.classList.add('custom');
				button.addEventListener('click', () => selectRechargeAmount(index, amount))
				button.appendChild(document.createElement('div')).textContent = `${amount.toFixed(0)}${currency}`;
			})
			for (var index in r.paymentMethods) {
				let method = r.paymentMethods[index];
				let div = methodsStrip.appendChild(document.createElement('div'));
				div.classList.add('payment-method');
				let title = div.appendChild(document.createElement('div'));
				title.classList.add('title');
				if (method.imageURL && method.id != 'transfer') {
					title.appendChild(document.createElement('img')).src = method.imageURL;
				} else {
					title.textContent = method.name;
				}
				let desc = div.appendChild(document.createElement('div'));
				let baseCost = parseFloat(method.cost);
				let cost = baseCost > 0 ? `${baseCost.toFixed(0)}${currency}` : "Gratuito";
				desc.append(cost);
				desc.appendChild(document.createElement('br'));
				desc.append(method.delay);
				if (method.id == 'paypal') {
					let subdiv = div.appendChild(document.createElement('div'));
					subdiv.id = 'paypal';
					subdiv.style.padding = '10px';
					subdiv.style.paddingBottom = '50px';
					let script = div.appendChild(document.createElement('script'));
					script.innerText = `renderPaypalButton('${currencyCode}')`;
				} else {
					let button = div.appendChild(document.createElement('button'));
					button.classList.add('smaller');
					button.classList.add('custom');
					button.classList.add('recharge-method-button');
					button.disabled = true;
					button.textContent = 'CONFIRMAR';
					button.addEventListener('click', () => recharge(selectedRechargeAmount, method.id));
				}
			}
			callback();
		} else {
			showMessage("Error", r.message);
		}
	});
}

function updateWithdraws(callback: () => void) {
	backend.checkWithdraws().then(r => {
		console.log("Check withdraws", r);
		if (r.pending) {
			showPendingWithdrawDialog(parseFloat(r.pending.amount).toFixed(2), r.pending.instructions || "Se ha recibido tu solicitud de retirada de fondos. La operación está en proceso.");
		} else if (r.success) {
			let currency = session.user.currency.symbol;
			let rechargeStrip = document.getElementById('withdraw-button-strip');
			let methodsStrip = document.getElementById('withdraw-method-button-strip');
			Array.from(rechargeStrip.children).forEach(n => n.remove());
			Array.from(methodsStrip.children).forEach(n => n.remove());
			if (!r.withdraws) {
				showMessage("RETIRAR", r.message || 'No dispone de fondos suficientes para realizar esta operación', {
					color: 'yellow',
					callback: () => {
						console.log("Forced logout");
						JSCookie.remove("sessionID");
						session = {};
						showTab(undefined);
					}
				});
				return;
			}
			r.withdraws.forEach((amount, index) => {
				let button = rechargeStrip.appendChild(document.createElement('button'));
				button.classList.add('big');
				button.classList.add('custom');
				button.addEventListener('click', () => selectWithdrawAmount(index, amount))
				button.appendChild(document.createElement('div')).textContent = `${amount.toFixed(0)}${currency}`;
			});
			r.withdrawMethods.forEach((method, index) => {
				let div = methodsStrip.appendChild(document.createElement('div'));
				div.classList.add('payment-method');
				let title = div.appendChild(document.createElement('div'));
				title.classList.add('title');
				console.log(method);
				if (method.imageURL && method.id != 'transfer') {
					title.appendChild(document.createElement('img')).src = method.imageURL;
				} else {
					title.textContent = method.name;
				}
				let desc = div.appendChild(document.createElement('div'));
				let cost = parseFloat(method.cost) > 0 ? `${parseFloat(method.cost).toFixed(0)}${currency}` : "Gratuito";
				desc.append(cost);
				desc.appendChild(document.createElement('br'));
				desc.append(method.delay);
				let button = div.appendChild(document.createElement('button'));
				button.classList.add('smaller');
				button.classList.add('custom');
				button.classList.add('withdraw-method-button');
				button.disabled = true;
				button.textContent = 'CONFIRMAR';
				button.addEventListener('click', () => showWithdrawDialog(selectedWithdrawAmount, method.id));
			});
			callback();
		} else {
			showMessage("ERROR", r.message);
		}
	});
}

function updateCountries() {
	backend.countries().then(result => {
		countries = result;
		let countryList = carouselObjects['country-list'];
		let index = 0;
		visibleCountryIndex = [];
		countryList.remove(countryList.getCellElements());
		countries.forEach(country => {
			if (country.hidden) {
				console.log("Ignoring country", index, country);
				index++;
				return;
			}
			let div = document.createElement('div');
			let flagUrl = country.flag_url;
			if (!flagUrl.startsWith('http')) {
				flagUrl = "https://" + backend.server + '/flag?country=' + flagUrl;
			}
			div.classList.add('carousel-cell');
			div.appendChild(document.createElement('img')).src = flagUrl;
			countryList.append(div);
			visibleCountryIndex.push(index++);
		});
		if (selectedCountry == undefined || selectedCountry >= countries.length) {
			selectedCountry = 0;
		}
		countryList.select(0);
		carouselObjects['country-list'].resize();
		updateCurrencies();
	});
}

function updateCurrencies() {
	if (countries[selectedCountry]) {
		let currencies = countries[selectedCountry].currencies;
		let currencyList = carouselObjects['currency-list'];
		currencyList.remove(currencyList.getCellElements());
		currencies.forEach(currency => {
			let div = document.createElement('div');
			div.classList.add('carousel-cell');
			div.classList.add('white');
			div.appendChild(document.createElement('span')).textContent = currency.symbol;
			currencyList.append(div);
		});
		currencyList.select(0);
		selectedCurrency = 0;
	}
}

function updateFAQ(callback: () => void) {
	backend.getFAQ().then(result => {
		if (result.success) {
			let container = document.getElementById('faq-container');
			for(var item of result.faq) {
				item.content = item.content.replace('\n', '<br>');
			}
			populateWithElements(container, result.faq, ['subject', 'content'], undefined, true);
			callback();
		} else {
			showMessage("Error", result.message);
		}
	});
}

function showNotificationPopup() {
    if (session.user.notifications && session.user.notifications.length > 0) {
        let notification = session.user.notifications[0];
        showNotification(notification);
        backend.markNotificationAsShown(notification.id).then(r => {
            if (r.success) {
                session.user.notifications = [];
                updateUserInfo();
            }
        });
    }
}

function updateNotifications(callback: () => void) {
	backend.checkUser().then(result => {
		if (result.success) {
			console.log("User notifications updated", result);
			session.user = result;
			updateUserInfo();
			let container = document.getElementById('notifications-history');
			populateWithElements(container, result.notifications, ['date', 'text', 'subtext']);
			callback();
		} else {
			showMessage("Error", result.message);
		}
	});
}

function updateUserInBackground() {
    if (anyDialogVisible()) {
        return;
    }
	if (session && session.user) {
		backend.checkUser().then(result => {
			if (result.success) {
				session.user = result;
				updateUserInfo();
                showNotificationPopup();
			}
		});
	} else {
		console.log("Broken session object", session);
	}
}

function updateUser(callback: () => void) {
	if (session && session.user) {
		backend.checkUser().then(result => {
			if (result.success) {
				console.log("User updated", result);
				session.user = result;
				updateUserInfo();
				callback();
			} else {
				showMessage("Error", result.message);
			}
		});
	}
}

function updateUserInfo() {
	let user = session.user;
	let accountDesc = (user.account_type == 'VIP' ? "Cuenta VIP" :
		(user.account_type == 'enhanced' ? "Cuenta avanzada" : "Cuenta estándar"));
	
	let bullet = document.getElementById('notifications-bullet');
	bullet.style.visibility = (user.notifications && user.notifications.length > 0 ? 'shown' : 'hidden');
	
	for (let obj of document.querySelectorAll('.data-name'))
		obj.textContent = user.name || "";
	for (let obj of document.querySelectorAll('.data-fullname'))
		obj.textContent = `${user.name || " "} ${user.surname || ""}`;
	for (let obj of document.querySelectorAll('.data-email'))
		obj.textContent = user.email;
	for (let obj of document.querySelectorAll('.data-phone'))
		obj.textContent = user.mobile;
	for (let obj of document.querySelectorAll('.data-document'))
		obj.textContent = user.documentType?.toUpperCase() + " " + user.document;
	for (let obj of document.querySelectorAll('.data-balance'))
		obj.textContent = user.wallet.toFixed(2);
	for (let obj of document.querySelectorAll('.data-currency-symbol'))
		obj.textContent = user.currency.symbol;
	for (let obj of document.querySelectorAll('.data-currency-name'))
		obj.textContent = user.currency.name;
	for (let obj of document.querySelectorAll('.data-currency-plural'))
		obj.textContent = user.currency.plural.toLowerCase();
	for (let obj of document.querySelectorAll('.data-points'))
		obj.textContent = user.points.toFixed(0);
	for (let obj of document.querySelectorAll('.data-points-conversion'))
		obj.textContent = (user.pointsConversionResult || 0).toFixed(2);
	for (let obj of document.querySelectorAll('.data-account-type'))
		obj.textContent = accountDesc;
	
	const noName = (!user.name || user.name.length == 0) || (!user.surname || user.surname.length == 0);
	const noPhone = (!user.mobile || user.mobile.length == 0) && !noName;
	//console.log("noPhone", noPhone, user.mobile);
	//console.log("noName", noName, user.name, user.surname);
	if (noName) {
		document.getElementById('profile-hint').style.display = '';
		document.getElementById('profile-phone').style.display = 'none';
		//console.log("Showing name hint");
	} else if (noPhone) {
		document.getElementById('profile-hint').style.display = 'none';
		document.getElementById('profile-phone').style.display = '';
		//console.log("Showing phone hint");
	} else {
		document.getElementById('profile-hint').style.display = 'none';
		document.getElementById('profile-phone').style.display = 'none';
		//console.log("Showing no hint");
	}
}

function performLogout() {
	showMessage("DESCONECTAR", 'Si desconecta, tendrá que volver a introducir sus credenciales en este dispositivo.<div style="text-align: center; margin-top: 40px">¿Está seguro?</div>', {
		showCancel: true,
		color: 'yellow',
		callback: () => {
			console.log("Forced logout");
			JSCookie.remove("sessionID");
			session = {};
			showTab(undefined);
		}
	});
}

function hideErrorBubble(prefix: string) {
	let errorMessage = document.getElementById(prefix + '-error-message');
	errorMessage.style.visibility = 'hidden';
}

function showErrorBubble(prefix: string, message: string) {
	console.log(`Showing error bubble: ${message}`);
	const headShake = [
		{ translateX: 0 },
		{ translateX: -12, rotateZ: -4.5 },
		{ translateX: 10, rotateZ: 3.5 },
		{ translateX: -6, rotateZ: -2.5 },
		{ translateX: 4, rotateZ: 1.5 },
		{ translateX: 0, rotateZ: 0 },
	];
	anime({
		targets: '#bg', keyframes: headShake, duration: 500, easing: 'easeOutQuad', complete: () => {
			let errorText = document.getElementById(prefix + '-error-text');
			let errorMessage = document.getElementById(prefix + '-error-message');
			errorMessage.style.visibility = 'visible';
			errorText.textContent = message;
			anime({ targets: `#${prefix}-error-message`, scale: [0.3, 1], duration: 500 });
		}
	});
}

function performLogin() {
	let email = (<HTMLInputElement>document.getElementById('login-email')).value;
	let password = (<HTMLInputElement>document.getElementById('login-password')).value;
	console.log("Performing login with email " + email);
	backend.login(email, password, loginCode).then(result => { 
		if (result.success) {
			console.log("User logged in", result);
			session.user = result;
			let rememberCheckbox = <HTMLInputElement>document.getElementById('remember-password');
			if (rememberCheckbox.checked) {
				JSCookie.set('sessionID', result.session, {sameSite: 'strict'});
			} else {
				JSCookie.remove('sessionID');
			}
			updateUserInfo();
			selectTab(0, hideDialogs);
			locationHashChanged();
		} else {
			console.log("Login failed", result.message);
            const headShake = [
                { translateX: 0 },
                { translateX: -12, rotateZ: -4.5 },
                { translateX: 10, rotateZ: 3.5 },
                { translateX: -6, rotateZ: -2.5 },
                { translateX: 4, rotateZ: 1.5 },
                { translateX: 0, rotateZ: 0 },
            ];
            anime({
				targets: '#bg', keyframes: headShake, duration: 500, easing: 'easeOutQuad', complete: () => {
					let errorText = document.getElementById('login-error-text');
					let errorMessage = document.getElementById('login-error-message');
					errorMessage.style.visibility = 'visible';
					errorText.textContent = result.message;
                    anime({ targets: '#login-error-message', scale: [0.3, 1], duration: 500 });
                }
            });
		}
	});
}

function showNotification(notification: backend.Notification) {
    let titleBox = <HTMLElement>notificationDialog.querySelector('#notification-title');
    let textBox = <HTMLElement>notificationDialog.querySelector('#notification-text');
    let pointsBox = <HTMLElement>notificationDialog.querySelector('#notification-points');
    let pointsLabel = <HTMLElement>notificationDialog.querySelector('#notification-points-amount');
    let subtextBox = <HTMLElement>notificationDialog.querySelector('#notification-subtext');
    let image = <HTMLImageElement>notificationDialog.querySelector('#notification-image');

    if (notification.image_url) {
        image.src = notification.image_url;
        image.style.display = 'box';
    } else {
        image.style.display = 'none';
    }

    if (notification.points) {
        pointsLabel.innerText = notification.points.toFixed(0);
        pointsBox.style.display = 'box';
    } else {
        pointsBox.style.display = 'none';
    }

    titleBox.textContent = notification.title;
    textBox.textContent = notification.text;
    subtextBox.textContent = notification.subtext;
	showDialog(notificationDialog);
}

type MessageOptions = { 
    color?: 'green'|'yellow'|'blue'|'magenta',
    hideOk?: boolean,
    showCancel?: boolean,
    showTOC?: boolean,
    info?: Object,
    callback?: () => void,
};
function showMessage(title: string, message: string, options?: MessageOptions) {
	let showCancel = options?.showCancel ?? false;
    let showOk = options?.hideOk ? false : !showCancel; 
    let showTOC = options?.showTOC || false;
	let titleBar = <HTMLElement>messageDialog.querySelector('.dialog-title');
	let titleBox = <HTMLElement>messageDialog.querySelector('#message-title');
	let textBox = <HTMLElement>messageDialog.querySelector('#message-text');
	let confirmButton = <HTMLElement>messageDialog.querySelector('#message-confirm');
	let cancelButton = <HTMLElement>messageDialog.querySelector('#message-cancel');
	let okButton = <HTMLButtonElement>messageDialog.querySelector('#message-ok');
	let buttons = <HTMLElement>messageDialog.querySelector('#buttons');
	let toc = <HTMLElement>messageDialog.querySelector('#message-toc');
    let tocCheckbox = <HTMLInputElement>messageDialog.querySelector('#accept-message-toc');
	let info = <HTMLElement>messageDialog.querySelector('#message-info');

    buttons.querySelectorAll('form').forEach(n => n.remove());
	
	let color = "#0CEB8D";
	if (options?.color == 'blue') color = "#06D7ED";
	if (options?.color == 'magenta') color = "#F24B8B";
	if (options?.color == 'yellow') color = "#F4D232";

	textBox.innerHTML = message;
	titleBox.textContent = title;
	titleBar.style.background = color;
	confirmButton.style.display = showCancel ? 'inline-block' : 'none';
	confirmButton.style.background = color;
	cancelButton.style.display = showCancel ? 'inline-block' : 'none';
	cancelButton.style.background = color;
	okButton.style.display = showOk ? 'inline-block' : 'none';
	okButton.style.background = color;
    okButton.disabled = showTOC ? true : false;
    toc.style.display = showTOC ? 'block': 'none';
    if (showTOC)  {
        tocCheckbox.checked = false;
        buttons.style.opacity = '0.5';
        buttons.style.pointerEvents = 'none';
    } else {
        buttons.style.opacity = '1';
        buttons.style.pointerEvents = 'unset';
    }
	messageCallback = options?.callback;

    info.style.display = 'none';
    if (options && options.info) {
        info.style.display = 'block';
        let html = "<table>";
        for (var key in options.info) {
            html += `<tr><th>${key}</th><td>${options.info[key]}</td></tr>`;
        }
        html += "</table>";
        html += `
        <div class="print">
        <a href="${backend.baseurl}/purchase?id=${options.info["Pedido"]}&session_id=${backend.getSessionID()}" target="_blank">
            <span class="material-icons">print</span> <span>Versión para imprimir</span>
        </a>
        </div>`;
        info.innerHTML = html;
    }
	showDialog(messageDialog);
}

function toggleMessageTOCButton() {
	let buttons = <HTMLElement>messageDialog.querySelector('#buttons');
    let tocCheckbox = <HTMLInputElement>messageDialog.querySelector('#accept-message-toc');
    if (tocCheckbox.checked) {
        buttons.style.opacity = '1';
        buttons.style.pointerEvents = 'unset';
    } else {
        buttons.style.opacity = '0.5';
        buttons.style.pointerEvents = 'none';
    }
}

function confirmMessage() {
	hideDialogs();
	if (messageCallback) {
		messageCallback();
	}
}

function toggleRegisterButton() {
	let button = (<HTMLButtonElement>document.getElementById('register-button'));
	let check = (<HTMLInputElement>document.getElementById('accept-toc'));
	button.disabled = !check.checked;
}

let global = (window as any);
global.hideDialogs = hideDialogs;
global.showMessage = showMessage;
global.showTOC = showTOC;
global.showPOL = showPOL;
global.confirmMessage = confirmMessage;
global.confirmPurchase = confirmPurchase;
global.confirmPurchaseMethod = confirmPurchaseMethod;
global.performLogin = performLogin;
global.performLogout = performLogout;
global.performRegister = performRegister;
global.selectTab = selectTab;
global.selectHomeButton = selectHomeButton;
global.selectWalletButton = selectWalletButton;
global.selectWalletPage = selectWalletPage;
global.selectProfileButton = selectProfileButton;
global.selectRegisterPage = selectRegisterPage;
global.selectRechargeAmount = selectRechargeAmount;
global.selectWithdrawAmount = selectWithdrawAmount;
global.showLoginDialog = showLoginDialog;
global.showRememberPasswordDialog = showRememberPasswordDialog;
global.showRegisterDialog = showRegisterDialog;
global.showPurchaseDialog = showPurchaseDialog;
global.showPendingRechargeDialog = showPendingRechargeDialog;
global.showCompleteProfileDialog = () => showDialog(completeProfile);
global.showContactDialog = () => showDialog(contactDialog);
global.showAddPhoneDialog = () => showDialog(addPhoneDialog);
global.nextRegisterPage = () => selectRegisterPage(currentRegisterPage + 1);
global.prevRegisterPage = () => selectRegisterPage(currentRegisterPage - 1);
global.showNotifications = () => selectTab(4);
global.toggleRegisterButton = toggleRegisterButton;
global.recharge = recharge;
global.sendReminder = sendReminder;
global.performNewPassword = performNewPassword;
global.exchangePoints = exchangePoints;
global.performCompleteProfile = performCompleteProfile;
global.performAddPhone = performAddPhone;
global.performCancelRecharge = performCancelRecharge;
global.performCancelWithdraw = performCancelWithdraw;
global.sendContactMessage = sendContactMessage;
global.showFAQ = () => selectTab(5);
global.performWithdraw = performWithdraw;
global.selectInfoButton = selectInfoButton;
global.updateInfoCurrencies = updateInfoCurrencies;
global.updateInfoProducts = updateInfoProducts;
global.showInfoProducts = showInfoProducts;
global.updateUser = updateUser;
global.teleingresoLoaded = teleingresoLoaded;
global.selectPurchaseMethod = selectPurchaseMethod;
global.toggleMessageTOCButton = toggleMessageTOCButton;
global.performAddDocument = performAddDocument;